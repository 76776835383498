<template>
    <div class="create-new-user-card">
        <div v-if="isLoading">
            <Loading />
        </div>
        <!-- is-edit-mode is hard-coded for now, can tie to isEditable if necessary -->
        <UserDetailHeader
            v-if="!isNewUser"
            :user-id="userId"
            :company-id="companyId"
            :offices="offices"
            :is-edit-mode="true"
            :user-name="userFullName"
            :role="whatRoleAmI ? whatRoleAmI.text : ''"
            :unlockButton="unlockButton"
            @resetLogin="resetLogin"
            id="the_user_detail_unlock_button"
        />
        <h1 v-else>Create New User</h1>
        <ValidationObserver ref="form">
            <form @submit.prevent="isEditMode ? updateUser() : addNewUser()" class="user-personal-info">
                <div class="fullwidth bottom-20">
                    <Toggle
                        v-model="user.requiresSupervisor"
                        v-if="isClincalStaff"
                        id="Supervisor"
                        class="toggle-checkbox user-profile-sign-check"
                        name="Supervisor"
                        label="Requires Signing Supervisor"
                        :disabled="canEditSupervisor == true ? false : true"
                    />
                </div>
                <div v-if="user.role" class="flex colx3 space-between">
                    <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        class=""
                        placeholder="First Name"
                        v-model="user.firstName"
                        label="First Name"
                        required
                        :disabled="!isEditable"
                    />
                    <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        class=""
                        placeholder="Last Name"
                        v-model="user.lastName"
                        label="Last Name"
                        required
                        :disabled="!isEditable"
                    />
                    <Dropdown
                        v-model="user.role"
                        id="Role"
                        class=""
                        name="Role"
                        label="Role"
                        :options="roles"
                        required
                        :disabled="canEditRole == true ? false : true"
                    />
                </div>
                <div v-if="user.role" class="fullwidth flex colx3 wrap">
                    <Input
                        type="text"
                        name="phone"
                        id="phone"
                        class=""
                        placeholder="Phone Number"
                        v-model="user.phone"
                        label="Phone Number"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="email"
                        name="email"
                        id="email"
                        class=""
                        placeholder="Email"
                        v-model="user.email"
                        label="Email"
                        required
                        rules="email"
                        :disabled="!isEditable"
                    />
                </div>

                <div v-if="user.role && isClincalStaff" class="flex colx4 wrap space-between">
                    <Input
                        type="text"
                        name="licenses"
                        id="licenses"
                        placeholder="Licenses and Certifications"
                        v-model="user.licenses"
                        label="Licenses and Certifications"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="licenseNumber"
                        id="licenseNumber"
                        placeholder="License Number"
                        v-model="user.licenseNumber"
                        label="License Number"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="providerNpi"
                        id="providerNpi"
                        placeholder="Provider NPI #"
                        v-model="user.providerNpi"
                        label="Provider NPI #"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="providerTaxonomy"
                        id="providerTaxonomy"
                        placeholder="Provider Taxonomy"
                        v-model="user.providerTaxonomy"
                        label="Provider Taxonomy"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="deaNumber"
                        id="deaNumber"
                        placeholder="DEA #"
                        v-model="user.deaNumber"
                        label="DEA #"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="cfarsRaterId"
                        id="cfarsRaterId"
                        placeholder="CFARS Rater ID"
                        v-model="user.cfarsRaterId"
                        label="CFARS Rater ID"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="farsRaterId"
                        id="farsRaterId"
                        placeholder="FARS Rater ID"
                        v-model="user.farsRaterId"
                        label="FARS Rater ID"
                        :disabled="!isEditable"
                    />

                    <Input
                        type="text"
                        name="taxId"
                        id="taxId"
                        placeholder="Tax ID (not SSN)"
                        v-model="user.taxId"
                        label="Tax ID (not SSN)"
                        :disabled="!isEditable"
                    />
                </div>

                <button type="submit" v-show="isNewUser" :disabled="disableCreateButton">Add User</button>
                <div class="top-30 bottom-20">
                    <button
                        class="margin-0"
                        type="button"
                        v-show="!isNewUser && !isEditMode"
                        @click="setEditMode($event, true)"
                    >
                        <span class="material-icons-outlined">lock</span> Edit User
                    </button>
                    <button
                        class="margin-0"
                        type="submit"
                        v-show="!isNewUser && isEditMode"
                        :disabled="disableCreateButton"
                    >
                        <span class="material-icons-outlined">lock_open</span> Save Changes
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { auth, companies, users } from '@/util/apiRequests';
    import { mapGetters, mapState } from 'vuex';
    import { Roles } from '@/util/globalConstants';
    import UserDetailHeader from '@/components/users/UserDetailHeader';
    import dayjs from 'dayjs';
    import Loading from '@/components/general/loading/loading';

    const emptyUser = {
        role: '',
        photo: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        licenses: '',
        licenseNumber: '',
        providerNpi: '',
        providerTaxonomy: '',
        deaNumber: '',
        cfarsRaterId: '',
        farsRaterId: '',
        taxId: '',
        requiresSupervisor: false,
        login_fails: 0,
        tym_last_failed: new Date(),
        login_locked: false,
    };

    export default {
        name: 'UserDetail',
        props: {
            userId: [String, Number],
        },
        components: { UserDetailHeader, ValidationObserver, Loading },
        data() {
            return {
                roles: [],
                offices: [],
                user: { ...emptyUser },
                formIsDirty: false,
                isEditMode: false,
                disableCreateButton: false,
                isLoading: false,
            };
        },
        computed: {
            ...mapState({
                currentUser: 'user',
            }),
            ...mapGetters(['companyId']),
            isNewUser() {
                return this.userId === 'new';
            },
            isEditable() {
                if (this.userId === 'new') {
                    return !!this.pageMeta?.permissions?.create;
                } else {
                    if (!this.pageMeta?.permissions?.edit && this.currentUser?.id != this.userId) {
                        return false;
                    }

                    return this.isEditMode;
                }
            },
            isClincalStaff() {
                const role = this.roles.find((role) => role.value === this.user.role);

                return !!role && ['admin', 'supervisor', 'clinician', 'super admin'].includes(role.text.toLowerCase());
            },
            canEditRole() {
                if (this.isEditable) {
                    if (this.whatRoleAmI?.text === 'Admin' || this.whatRoleAmI?.text === 'Super Admin') {
                        return true;
                    }
                }
                return false;
            },
            canEditSupervisor() {
                if (this.isEditable) {
                    //don't edit your own supervisor state ya fool
                    if (this.$route.params.userId == this.$store.state.user.id) {
                        return false;
                    }
                    if (this.whatRoleAmI.text == 'Admin' || this.whatRoleAmI?.text === 'Super Admin') {
                        return true;
                    }
                }
                return false;
            },
            whatRoleAmI() {
                const role = this.roles.find((role) => role.value === this.$store?.state.user.role_id);
                return role;
            },
            userFullName() {
                return `${this.user.firstName} ${this.user.lastName}`;
            },
            unlockButton() {
                const { login_fails, tym_last_failed, login_locked } = this.user;

                let display = false;

                const usr = this.$store.state.user;

                if (usr.role_id !== 1) {
                    display = false;
                } else if (!tym_last_failed) {
                    display = false;
                } else if (dayjs().isAfter(dayjs(tym_last_failed).add(1, 'day'))) {
                    display = false;
                } else if (login_locked && login_fails >= 7) {
                    display = true;
                }

                return display;
            },
        },
        methods: {
            async init() {
                this.isLoading = true;
                // Get list of roles
                const [rolesRes, officesRes] = await Promise.all([
                    this.$api.get(auth.roles()),
                    this.$api.get(companies.getOffices(this.companyId)),
                ]);

                this.roles = rolesRes.data.map((role) => ({ text: role.name, value: role.id }));
                this.offices = officesRes.data.map((office) => ({ text: office.office_name, value: office.id }));

                // Automatically select the role included in the query params
                if (this.$route.query.role && this.userId === 'new') {
                    const matchingRole = this.roles.find((role) => role.text === this.$route.query.role);

                    if (matchingRole) {
                        this.user.role = matchingRole.value;
                    }
                }

                this.isLoading = false;

                // Load user data if clientId is not 'new'
                if (!this.isNewUser) {
                    this.isLoading = true;

                    const res = await this.$api.get(users.getUserDetails(this.userId));

                    this.isLoading = false;

                    if (res.status === 404) {
                        this.$toasted.error('Could not find user');
                        return this.$router.replace({ name: 'NotFound' });
                    }

                    this.user = {
                        ...this.user,
                        ...res.data,
                        isActive: Boolean(res.data.isActive),
                        use2fa: Boolean(res.data.use2fa),
                    };
                } else {
                    this.user.primaryOfficeId = this.offices[0]?.value || this.$store.state.user.office_id;
                }
            },
            async addNewUser() {
                const isValid = await this.validateForm();

                if (isValid) {
                    try {
                        if (this.user?.email) {
                            this.user.email = this.user?.email.toLowerCase().trim();
                        }

                        const { login_fails, tym_last_failed, login_locked, ...rest } = this.user;

                        const company_id =
                            this.user.role == Roles.SUPER_ADMIN
                                ? this.$store.state.defaultCompanyId
                                : this.$store.state.user.company_id;

                        this.disableCreateButton = true;

                        const res = await this.$api.post(users.createNewUser(), { ...rest, companyId: company_id });

                        if (res.status === 409) {
                            this.$toasted.error('A user with this email address already exists');
                            this.$set(this.user, 'email', '');
                            this.disableCreateButton = false;
                            return;
                        }

                        if (res.status < 300) {
                            this.$toasted.success('Successfully created new user.');
                            await this.$router.push({ name: 'UserDetail', params: { userId: res.data.user_id } });
                        } else {
                            this.$toasted.error('Failed to create user. Please try again later.');
                        }
                        this.disableCreateButton = false;
                    } catch (err) {
                        this.$toasted.error('Failed to create user. Please try again later.');
                    }
                }
            },
            async updateUser(e) {
                const isValid = await this.validateForm();

                if (isValid) {
                    try {
                        this.disableCreateButton = true;
                        if (this.user?.email) {
                            this.user.email = this.user?.email.toLowerCase().trim();
                        }
                        const res = await this.$api.put(users.updateUser(this.userId), {
                            cfars_rater_id: this.user.cfarsRaterId,
                            dea_number: this.user.deaNumber,
                            email: this.user.email,
                            fars_rater_id: this.user.farsRaterId,
                            first_name: this.user.firstName,
                            active: this.user.isActive,
                            last_name: this.user.lastName,
                            license_number: this.user.licenseNumber,
                            licenses_certs: this.user.licenses,
                            phone: this.user.phone,
                            office_id: this.user.primaryOfficeId,
                            profile_photo_file_id: this.user.profile_photo_file_id,
                            npi: this.user.providerNpi,
                            taxonomy: this.user.providerTaxonomy,
                            recent_clients: this.user.recentClients,
                            reminders: this.user.reminders,
                            role_id: this.user.role,
                            tax_id: this.user.taxId,
                            use_2fa: this.user.use2fa,
                            user_color: this.user.user_color,
                            requires_sup: this.user.requiresSupervisor,
                        });
                        if (res.status === 409) {
                            this.$toasted.error('A user with this email address already exists');
                            this.$set(this.user, 'email', '');
                            return;
                        }

                        if (res.status < 300) {
                            this.$toasted.success('Successfully updated user.');
                            this.setEditMode(false);
                        } else {
                            this.$toasted.error('Failed to update user. Please try again later.');
                        }
                        this.disableCreateButton = false;
                    } catch (err) {
                        this.$toasted.error('Failed to update user. Please try again later.');
                    }
                }
            },
            async validateForm() {
                const isValid = await this.$refs.form.validate();

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.');
                }

                return isValid;
            },
            setEditMode(e, toggle) {
                this.isEditMode = toggle;
            },
            async resetLogin() {
                try {
                    this.isLoading = true;
                    await this.$api.patch(auth.resetUser(), { userId: this.userId });
                    this.isLoading = false;
                    this.$toasted.success('Successfully reset logins.');
                    await this.init();
                } catch (err) {
                    this.$toasted.error('Failed to reset logins. Please try again later.');
                }
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            user: {
                handler(newVal) {
                    if (typeof newVal.role === 'string' && newVal.role.length > 0) {
                        newVal.role = parseInt(newVal.role);
                    }
                },
                deep: true,
            },
            async $route(newVal) {
                if (newVal.params.userId === 'new') {
                    this.user = { ...emptyUser };
                }

                await this.init();
            },
        },
    };
</script>
